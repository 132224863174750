import React, { useEffect, useState } from 'react';
import useManualApiWithLoading from 'Hooks/useManualApiWithLoading';
import { Select, Checkbox, Input, Button } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { PaginatedTag, Tag } from 'types/Tags';
import { getTags, postTags } from 'Services/Tag.service';
import { getNotification } from 'Components/GetNotification';

const { Option } = Select;

export const convertTagsToStringArray = (selectedTags: string[] | Tag[]): string[] => {
  if (selectedTags.length === 0) {
    return [];
  }

  const sanitizedSelectedTags = selectedTags?.filter(tag => tag !== null);

  if (typeof sanitizedSelectedTags[0] === 'string') {
    return sanitizedSelectedTags as string[];
  }

  return (sanitizedSelectedTags as Tag[])?.map(tag => tag?.name);
};

type DropdownWithCheckboxProps = {
  id: any,
  selectedTags: string[] | Tag[];
  setSelectedTags: any,
  tagsToBeRemoved?: string[] | Tag[];
  setTagsToBeRemoved?: any,
}

const DropdownWithCheckbox: React.FC<DropdownWithCheckboxProps> = ({id, tagsToBeRemoved = [], setTagsToBeRemoved = () => {}, setSelectedTags, selectedTags = [] }) => {
  const [originalTags, setOriginalTags] = useState<string[] | Tag[]>();
  const [tags, setTags] = useState<string[]>([]);
  const [filteredTags, setFilteredTags] = useState<string[]>([]);
  const [newTag, setNewTag] = useState<string>('');

  const onTagSelected = (tag: string, checked: boolean) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter(t => t !== tag);
    setSelectedTags(nextSelectedTags);
  };

  const { 
    data: createdTagData,
    setData: setCreatedTagData,
    error: createTagError,
    callFunction: createTag 
  } = useManualApiWithLoading({
    apiFunction: postTags
  });

  const { 
    data: tagsData,
    error: tagsError,
    callFunction: fetchTags 
  } = useManualApiWithLoading<any>({
    apiFunction: getTags
  });

  useEffect(() => {
    fetchTags()
    setOriginalTags(selectedTags)
  }, [id]);

 useEffect(() => {
    if(JSON.stringify(originalTags) === JSON.stringify(selectedTags)) {
      setTagsToBeRemoved([]);
    }

 }, [originalTags, selectedTags])

  useEffect(() => {
    if(originalTags && originalTags?.length > 0) {
      
      const tagDataResults = (tagsData?.data as PaginatedTag)?.results;
      const unselectedTags = originalTags?.filter((t:any) => !selectedTags?.includes(t))

      const deletedTags = tagDataResults?.filter(t => unselectedTags.includes(t?.name))
      setTagsToBeRemoved(deletedTags)
    }

 }, [id, selectedTags]);


  const onAddTag = () => {
    if (newTag) {
      createTag({
        name: newTag,
      });
    }
  };

  const onClickTagCheckbox = (e: CheckboxChangeEvent, tag: string) => {
    onTagSelected(tag, e.target.checked);
  };

  useEffect(() => {
    if (tagsData) {
      const tagDataResults = (tagsData?.data as PaginatedTag)?.results;
      setTags(convertTagsToStringArray(tagDataResults));
      setFilteredTags(convertTagsToStringArray(tagDataResults));
    }
  }, [tagsData]);

  useEffect(() => {
    if (createdTagData) {
      setTags([...tags, newTag]);
      setSelectedTags([...selectedTags, newTag]);
      setNewTag('');
      setCreatedTagData(undefined);
    }

    if (tagsError) {
      getNotification('error', {
        header: 'Error',
        body: 'Tag could not be created',
      });
    }

    if(createTagError){
      const message = createTagError?.name ? 'Tag has already been added' : 'Could not create Tag'
      getNotification('error', {
        header: 'Error',
        body: (createTagError as any)?.response?.status == 403 ? 'You do not have permission to perform this action.' : message,
      });
    }
  }, [createdTagData, tagsError, createTagError]);

  const handleSearch = (value: string) => {
    const filtered = tags.filter(tag => tag.toLowerCase().includes(value.toLowerCase()));
    setFilteredTags(filtered);
  };

  return (
    <>
      <Select
        mode="multiple"
        style={{ width: '100%' }}
        placeholder="Search Tags"
        className='text-lg'
        value={convertTagsToStringArray(selectedTags)}
        filterOption={false}
        onSearch={handleSearch}
        dropdownRender={() => (
          <div>
            <div style={{minHeight:'100px',height:'100%', maxHeight: '300px'}} className="overflow-y-auto">
              {convertTagsToStringArray(filteredTags)?.map(tag => (
                <div key={tag} style={{ display: 'flex', alignItems: 'center', paddingLeft: '8px' }}>
                  <Checkbox
                    checked={convertTagsToStringArray(selectedTags).includes(tag)}
                    onChange={(e: CheckboxChangeEvent) => onClickTagCheckbox(e, tag)}
                  >
                    <div className="flex flex-row gap-2">
                      <span>{tag}</span>
                      {/* <EditOutlined className="" onClick={() => setVisible(true)} /> */}
                    </div>
                  </Checkbox>
                </div>
              ))}
            </div>
            <div className='flex flex-row justify-around'>
              <Input
                value={newTag}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewTag(e.target.value)}
                onPressEnter={onAddTag}
                placeholder="Add Tag"
                style={{ width: 'calc(100% - 32px)', margin: '5px' }}
              />
              <Button
                type="primary"
                onClick={onAddTag}
                style={{ width: '32px', margin: '5px' }}
              >
                +
              </Button>
            </div>
          </div>
        )}
        onChange={setSelectedTags as (value: string[]) => void}
      >
        {convertTagsToStringArray(filteredTags).map(item => (
          <Option key={item} value={item}>
            {item}
          </Option>
        ))}
      </Select>
    </>
  );
};

export default DropdownWithCheckbox;
